<template>
  <!--
    Used to edit the configuration of the device.
  -->
  <div class="deviceDetailConfigurationEdit">
    <LoadingPlaceholder v-if="loading" />
    <div v-else>
      <label class="mt-2">{{ $t('deviceDetailConfigurationEditComponent.configurationData') }}:</label>
      <textarea
        ref="confTextArea" 
        v-model="configuration.configuration" 
        class="form-control fafa"
      />
      <label class="mt-2">{{ $t('deviceDetailConfigurationEditComponent.applicationUrl') }}:</label>
      <input 
        v-model="configuration.applicationUrl" 
        class="form-control" 
        type="text"
      >
      <label class="mt-2">{{ $t('deviceDetailConfigurationEditComponent.otherConfigDraft') }}:</label>
      <select
        v-if="configurations"
        v-model="selectedConfigurationId"
        class="form-control mt-2 mb-3"
        @change="changeConfiguration()"
      >
        <option
          :value="null"
          disabled
        >
          {{ $t('pleaseSelectOne') }}
        </option>
        <option
          v-for="(altConfig, index) in configurations"
          :key="`deviceDetailConfigurationEdit-selectOption-${ index }`"
          :value="altConfig.id"
        >
          {{ checkConfigName(altConfig.name) }}
        </option>
      </select>
      <div class="mt-2 clearfix">
        <button
          class="btn btn-secondary float-left"
          @click="toggleConfiguration()"
        >
          <font-awesome-icon
            class="mr-2 gray"
            icon="times"
          />
          <span>{{ $t('cancel') }}</span>
        </button>
        <button
          class="btn btn-primary float-right ml-2"
          @click="saveConfiguration()"
        >
          <font-awesome-icon
            class="mr-1"
            icon="upload"
          />
          <span>
            {{ $t('save') }}
          </span>
        </button>
        <button
          v-if="restartAppCommandAvailable"
          class="btn btn-primary float-right"
          @click="saveConfigurationAndRestartApp()"
        >
          <font-awesome-icon
            class="mr-2"
            icon="sync-alt"
            style="vertical-align: middle;"
          />
          {{ $t('deviceDetailConfigurationEditComponent.saveAndRestart') }}
        </button>
        <!-- installCommandAvailable -->
        <button
          v-if="type == 'sm_tracker_v4'"
          class="btn btn-primary float-right"
          @click="saveConfigurationAndInstallTracker()"
        >
          <font-awesome-icon
            class="mr-2"
            icon="sync-alt"
            style="vertical-align: middle;"
          />
          {{ $t('deviceDetailConfigurationEditComponent.saveAndInstall') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DeviceDetailConfigurationEdit',
  props: {
    uuid: {
      type: String,
      required: true
    },
    configurationId: {
      type: Number,
      required: true
    },
    restartAppCommandAvailable: {
      type: Boolean,
      required: true
    },
    restartAppCommand: {
      type: Object,
      required: true
    },
    installCommandAvailable: {
      type: Boolean,
      required: true
    },
    installCommand: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      required: false,
      default () {
        return null;
      }
    }
  },
  data () {
    return {
      loading: true,
      selectedConfigurationId: null,
      configuration: null,
      configurations: null
    }
  },
  watch: {
    'configuration.configuration' () {
      if(this.$refs.confTextArea) {
        this.resizeArea();
      }
    }
  },
  mounted () {
    this.$nextTick(function () {
      setTimeout(() => {
          this.resizeArea();
      }, 500);
    });
  },
  created () {
    this.selectedConfigurationId = this.configurationId;

    this.getConfig();
    this.getAllConfigs();
  },
  methods: {
    resizeArea () {
      var str = this.$refs.confTextArea.value;
      this.$refs.confTextArea.rows = str.split("\n").length;
    },
    getAllConfigs () {
      this.axios.get(`/CentralDeviceManagement/GetAllDeviceConfigurations?deviceUuid=${ this.uuid }`)
        .then((response) => {
          if (!response) {
            return;
          }
          if (!response.data) {
            return;
          }
          this.configurations = response.data;
        });
    },
    getConfig () {
      this.loading = true;

      this.axios.get(`/CentralDeviceManagement/GetDeviceConfigurationById?deviceUuid=${ this.uuid }&configurationId=${ this.selectedConfigurationId }`)
        .then((response) => {
          if (!response) {
            return;
          }
          if (!response.data) {
            return;
          }
          this.configuration = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },    
    changeConfiguration () {
      if (this.selectedConfigurationId == null) {
        return;
      }
      this.getConfig();
      //let updateDevice = this.device;
      //updateDevice.activeDeviceConfigurationId = this.selectedConfigurationId;
      //this.axios.put(`/CentralDeviceManagement/UpdateDeviceAsync?${ this.uuid }`, updateDevice);
    },
    checkConfigName (value) {
      let replacement = this.$t('deviceDetailConfigurationEditComponent.noNameConfig');
      if (value == null) {
        return replacement;
      }
      if (value.length == 0) {
        return replacement;
      }
      return value;
    },
    async saveConfiguration () {
      let newConfiguration = {
        name: this.configuration.name,
        configuration: this.configuration.configuration,
        applicationUrl: this.configuration.applicationUrl
      };
      await this.axios.put(`/CentralDeviceManagement/UpdateDeviceConfigurationById?deviceUuid=${ this.uuid }&configurationId=${ this.selectedConfigurationId }`, newConfiguration)
        .then((response) => {
          if (!response) {
            this.loading = false;
            return;
          }
          if (!response.status) {
            this.loading = false;
            return;
          }
          if (response.status != 200) {
            this.loading = false;
            return;
          }
          this.toggleConfiguration();
          this.$emit(`getConfiguration`);
        });
    },
    async restartApp () {
      this.executing = true;
      this.restartAppCommand.lastRequest = Date.now();
      this.$nextTick(() => {
        window.$('[data-toggle="tooltip"]').tooltip();
      });
      this.response = null;
      let commandRequest = {
        'Command': this.restartAppCommand.name,
        'Args': ''
      };
      await this.axios.post(`/CentralDeviceManagement/ExecuteCommand?deviceUuid=${ this.uuid }`, commandRequest)
        .then((response) => {
          if (response.data && response.data.output) {
            this.response = response.data.output;
          }
        })
        .finally(() => {
          this.executing = false;
        });
    },
    async installTracker () {
      //console.log(this.installCommand.name);
      this.executing = true;
      this.restartAppCommand.lastRequest = Date.now();
      this.$nextTick(() => {
        window.$('[data-toggle="tooltip"]').tooltip();
      });
      this.response = null;
      let commandRequest = {
        'Command': this.installCommand.name,
        'Args': ''
      };
      await this.axios.post(`/CentralDeviceManagement/ExecuteCommand?deviceUuid=${ this.uuid }`, commandRequest)
        .then((response) => {
          if (response.data && response.data.output) {
            this.response = response.data.output;
          }
        })
        .finally(() => {
          this.executing = false;
        });
    },
    toggleConfiguration () {
      this.$emit(`toggleConfiguration`);
    },
    async saveConfigurationAndRestartApp (){
      await this.saveConfiguration();
      await this.restartApp();
    },
    async saveConfigurationAndInstallTracker (){
      await this.saveConfiguration();
      await this.installTracker();
    }
  }
}
</script>

<style scoped>
textarea {
  width: 100%;
}
</style>
